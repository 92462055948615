<template>
    <Modal :shown="emailing" @close="$emit('update:emailing', false)">
        <template #default>
            <div class="modal-header">
                <h5 class="modal-title">Filter & Email Data</h5>
                <button class="close float-none"
                        type="button"
                        aria-label="Close"
                        @click="$emit('update:emailing', false)">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form @submit.prevent="sendEmail()">
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group text-left ml-3">
                            <label for="from-date">From: </label>
                            <input v-model.lazy="query.from_date"
                                   @keydown.enter.prevent
                                   :max="query.to_date || currentDate"
                                   id="from-date"
                                   type="date"
                                   required
                                   class="form-control form-control-sm">
                        </div>
                        <div class="form-group text-left ml-3">
                            <label for="to-date">To: </label>
                            <input v-model.lazy="query.to_date"
                                   @keydown.enter.prevent
                                   :min="query.from_date || null"
                                   :max="currentDate"
                                   id="to-date"
                                   type="date"
                                   required
                                   class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group text-left ml-3">
                            <label for="to-date">Email To: </label>
                            <input v-model.lazy="query.recipient"
                                   @keydown.enter.prevent
                                   id="recipient"
                                   type="email"
                                   required
                                   class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit"
                            :disabled="sending"
                            class="btn btn-sm btn-falcon-default">
                        <span v-if="sending" class="spinner-border spinner-border-sm" aria-hidden="true" />
                        <i v-else class="fa fa-envelope fa-fw" />
                        Email CSV
                    </button>
                </div>
            </form>
        </template>
    </Modal>
</template>

<script>
    import { formatISO } from 'date-fns';
    import Modal from '@/components/Modal';
    import { sendBulkPayoutReportEmail } from '@/services/PayoutReportService';

    export default {
        name: 'PayoutReportEmailModal',
        components: {
            Modal,
        },
        props: {
            emailing: { type: Boolean, default: false },
        },
        data() {
            return {
                sending: false,
                query: {
                    from_date: null,
                    to_date: null,
                    recipient: null,
                },
            };
        },
        computed: {
            currentDate() {
                return formatISO(new Date(), { representation: 'date' });
            },
        },
        methods: {
            async sendEmail(){
                this.sending = true;
                try {
                    await sendBulkPayoutReportEmail(this.query.from_date, this.query.to_date, this.query.recipient);
                    this.$alerts.success('Email Sent', `CSV export has been sent to ${this.query.recipient}`);
                } catch (err){
                    this.$alerts.danger('Failed to Send Email', 'Please contact support if this problem persists.');
                } finally {
                    this.sending = false;
                    this.$emit('update:emailing', false);
                }
            },
        },
    };
</script>
