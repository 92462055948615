<template>
    <div class="card">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-sm-0">Payout Reports</h5>
                </div>
                <div class="col-12 col-sm-8 col-md-7 col-lg-6 col-xxl-5 d-flex align-items-center">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                    <div class="dropdown d-inline-block ml-2">
                        <button class="btn btn-sm btn-falcon-default dropdown-toggle"
                                data-toggle="dropdown"
                                aria-label="Export"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class="fa fa-file fa-fw" />
                            Export
                        </button>
                        <div class="dropdown-menu dropdown-menu-right text-nowrap py-2">
                            <button class="dropdown-item" @click="exporting = true;">
                                <i class="fa fa-download" aria-hidden="true" /> Export&hellip;
                            </button>
                            <button class="dropdown-item" @click="emailing = true;">
                                <i class="fa fa-envelope" aria-hidden="true" /> Generate Report&hellip;
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <VTable v-if="payoutReports.length"
                    :data="payoutReports"
                    :filter="search"
                    :filter-fields="['_created_str', '_batch_effective_date_str', 'customer_bank_account.name', 'type',
                                     'amount']"
                    :initial-sort="{ field: 'created', order: false }"
                    :page-size="15">
                <template #header="{ sort, sortBy }">
                    <tr>
                        <VTableHeader field="created" v-bind="{ sort, sortBy }">
                            Creation Date
                        </VTableHeader>
                        <VTableHeader field="batch_effective_date" v-bind="{ sort, sortBy }">
                            Effective Date
                        </VTableHeader>
                        <VTableHeader field="type" v-bind="{ sort, sortBy }">
                            Direction
                        </VTableHeader>
                        <VTableHeader field="amount" v-bind="{ sort, sortBy }">
                            Amount
                        </VTableHeader>
                    </tr>
                </template>
                <template #row="{ item: report }">
                    <tr :key="report.id">
                        <th>
                            <router-link :to="{ name: 'payout-report-details', params: { id: report.id }}"
                                         v-if="$auth.hasPermissions('read:manual_payments')"
                                         role="button"
                                         class="contains-anchor">
                                {{ report._created_str }}
                            </router-link>
                            <template v-else>
                                {{ report._created_str }}
                            </template>
                        </th>
                        <td>{{ report._batch_effective_date_str }}</td>
                        <td>{{ report.type === 'C' ? 'Credit' : report.type === 'D' ? 'Debit' : 'Unknown' }}</td>
                        <td>{{ report.amount | formatCents }}</td>
                    </tr>
                </template>
            </VTable>
            <div v-else class="d-flex align-items-center justify-content-center flex-column p-3" style="height: 300px;">
                <i class="fas fa-money-bill-wave" style="font-size: 2rem;" />
                <span class="mt-3">No payout reports available yet.</span>
                <small class="mt-2 w-50 text-center">
                    Once a payout has been created, they will appear here
                </small>
            </div>
        </div>
        <PayoutReportExportModal :exporting.sync="exporting" />
        <PayoutReportEmailModal :emailing.sync="emailing" />
    </div>
</template>

<script>
    import PayoutReportEmailModal from '@/components/PayoutReportEmailModal';
    import PayoutReportExportModal from '@/components/PayoutReportExportModal';
    import VTable from '@/components/VTable.vue';
    import VTableHeader from '@/components/VTableHeader.vue';

    export default {
        name: 'PayoutReportsList',
        components: {
            VTable,
            VTableHeader,
            PayoutReportEmailModal,
            PayoutReportExportModal,
        },
        props: {
            payoutReports: { type: Array, required: true, default: () => [] },
        },
        data() {
            return {
                exporting: false,
                emailing: false,
                search: '',
            };
        },
    };
</script>
