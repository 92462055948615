<template>
    <div ref="modal" class="modal" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-hidden="true">
        <div :class="{ 'modal-dialog': true, 'modal-dialog-centered': centered }">
            <div class="modal-content">
                <slot v-if="shown" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            /** The modal will be shown when set to `true`, and hidden when `false`. You must watch the `close`
             *  event and update this value to maintain consistent state when the user dismisses the modal. */
            shown: {
                type: Boolean,
                required: true,
            },
            /** Centers the modal vertically in the window */
            centered: {
                type: Boolean,
                default: false,
            },
            /** Prevents the user from dismissing the modal */
            nonDismissible: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            shown(newVal, oldVal) {
                if (!!newVal !== !!oldVal) {
                    $(this.$refs.modal).modal(newVal ? 'show' : 'hide');
                }
            },
        },
        mounted() {
            $(this.$refs.modal).on('show.bs.modal', () => void this.$emit('open'));
            $(this.$refs.modal).on('hide.bs.modal', () => void this.$emit('close'));

            // To make this reactive, we re-create the behavior manually
            $(this.$refs.modal).on('hidePrevented.bs.modal', () => {
                if (!this.nonDismissible) {
                    $(this.$refs.modal).modal('hide');
                }
            });

            // Handle the initial state of the shown prop
            if (this.shown) {
                $(this.$refs.modal).modal('show');
            }
        },
    };
</script>
