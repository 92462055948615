<template>
    <Modal :shown="exporting" @close="$emit('update:exporting', false)">
        <template #default>
            <div class="modal-header">
                <h5 class="modal-title">Filter & Export Data</h5>
                <button class="close float-none"
                        type="button"
                        aria-label="Close"
                        @click="$emit('update:exporting', false)">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group text-left ml-3">
                        <label for="from-date">From: </label>
                        <input v-model.lazy="query.from_date"
                               @keydown.enter.prevent
                               :max="query.to_date || currentDate"
                               id="from-date"
                               type="date"
                               class="form-control form-control-sm">
                    </div>
                    <div class="form-group text-left ml-3">
                        <label for="to-date">To: </label>
                        <input v-model.lazy="query.to_date"
                               @keydown.enter.prevent
                               :min="query.from_date || null"
                               :max="currentDate"
                               id="to-date"
                               type="date"
                               class="form-control form-control-sm">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <FileDownloadButton :download-url="`soap/payout_reports/csv_export` + queryParams"
                                    :file-name="`Payout Report.csv`"
                                    class="btn btn-sm btn-falcon-default">
                    Export CSV
                </FileDownloadButton>
            </div>
        </template>
    </Modal>
</template>

<script>

    import FileDownloadButton from '@/components/FileDownloadButton';
    import { formatISO } from 'date-fns';
    import Modal from '@/components/Modal';

    export default {
        name: 'PayoutReportExportModal',
        components: {
            FileDownloadButton,
            Modal,
        },
        props: {
            exporting: { type: Boolean, default: false },
        },
        data() {
            return {
                query: {
                    from_date: null,
                    to_date: null,
                },
            };
        },
        computed: {
            currentDate() {
                return formatISO(new Date(), { representation: 'date' });
            },
            queryParams() {
                const queryString = Object.entries(this.query)
                    .filter(([, value]) => value)
                    .map(([key, value]) => key + '=' + value)
                    .join('&');

                return queryString && ('?' + queryString);
            },
        },
    };
</script>
